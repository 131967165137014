import React, {useEffect, useState} from 'react'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import CallDescription from '../components/CallDescription'
import CallCandidates from '../components/CallCandidates'
import {createFinancierCallsSelectors, useGetFinancierCallsQuery} from '../FinanciersApiSlice'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../data/redux/Store'
import {stripHTML} from '../../../../utils/stringHelpers'
import {printDateWithOrdinal} from '../../../../utils/dateHelpers'
import useAuthToken from '../../../../hooks/useAuthToken'
import {Spinner} from 'react-bootstrap'
import Overview from '../components/callOverview'
import {useIntl} from 'react-intl'

const CallsDetailsPage: React.FC = () => {
  const params = useParams<{financierId: string; callId: string}>()
  const {financierId, callId} = params
  const navigate = useNavigate()
  const location = useLocation()
  const isTokenAvailable = useAuthToken()
  const {isFetching, isError} = useGetFinancierCallsQuery({id: financierId || '', searchParams: {}},
    {skip: !isTokenAvailable})

  const {selectById: selectCallsById} = createFinancierCallsSelectors(financierId || '')

  const call = useSelector((state: RootState) => selectCallsById(state, callId || ''))
  const [activeTab, setActiveTab] = useState('callOverview')
  useEffect(() => {
    if (location.state?.fromHeader) {
      setActiveTab('candidates')
    }
  }, [location.state])

  const intl = useIntl()

  const tabs = [
    {
      id: 'callDescription',
      label: intl.formatMessage({id: 'MENU.CALLS.CALLDESCRIPTION', defaultMessage: 'Call Description'}),
      icon: 'fas fa-pen',
      activeColor: 'text-danger',
    },
    {
      id: 'candidates',
      label: intl.formatMessage({id: 'MENU.CALLS.CANDIDATES', defaultMessage: 'Candidates'}),
      icon: 'fas fa-user-plus',
      activeColor: 'text-danger',
    },
    {
      id: 'callOverview',
      label: intl.formatMessage({id: 'MENU.CALLS.DASHBOARD', defaultMessage: 'Dashboard'}),
      icon: 'fas fa-chart-bar',
      activeColor: 'text-danger',
    },
  ]

  const plaintext = stripHTML(call?.description ?? '') as string
  const requirements = stripHTML(call?.requirements || '', true) as string[]
  const callAmount = `${call?.currency ?? ''} ${
    call?.callAmount ? call.callAmount.toLocaleString() : 'NOT SPECIFIED'
  }`
  const locationScope = call?.country?.map((country) => ({
    name: country.name,
    regions: country.regions ?? [],
  })) ?? []


  if (isFetching) {
    return (
      <div className="d-flex justify-content-center flex-column gap-3 align-items-center h-100">
        <Spinner animation="border" variant="danger" />
        <p className="pt-0">Loading...</p>
      </div>
    )
  } else if (isError) {
    return <div className="container mt-4">Call Details not found</div>
  } else {
    return (
      <div className="d-flex flex-column align-items-start">
        <h3 className="mb-2 cursor-pointer" onClick={() => navigate(-1)}>
          <i className="fas fa-arrow-left text-dark pe-5"></i>
          {call?.name ?? ''}
        </h3>
        <div className="card border border-gray-300 w-100 mb-6">
          <div className="d-flex border-bottom border-gray-200">
            {tabs.map((tab) => (
              <button
                key={tab.id}
                className={`d-flex align-items-center px-4 py-4 ms-8 me-3 btn btn-link fs-6 ${
                  activeTab === tab.id
                    ? `${tab.activeColor} border-bottom border-2 border-danger`
                    : 'text-muted'
                }`}
                onClick={() => setActiveTab(tab.id)}
              >
                <i className={`${tab.icon} ${activeTab === tab.id ? tab.activeColor : ''}`}></i>
                <span className={activeTab === tab.id ? tab.activeColor : ''}>{tab.label}</span>
              </button>
            ))}
          </div>
          {activeTab === 'callOverview' && (
            <Overview id={callId ?? ''} />
          )}
          {activeTab === 'callDescription' && (
            <CallDescription
              description={plaintext}
              qualifications={requirements}
              postedOn={printDateWithOrdinal(call?.dateUploaded ?? '')}
              fundAmount={callAmount}
              sectors={call?.sectors ?? []}
              locationScope={locationScope}
              deadline={printDateWithOrdinal(call?.deadline ?? '')}
            />
          )}
          {activeTab === 'candidates' && <CallCandidates />}
        </div>
      </div>
    )
  }
}

export default CallsDetailsPage
