import React from "react";

type IProps = {
  width?: number;
  title?: string;
  children: React.ReactNode;
  show?: boolean;
  close?: () => void;
  height?: number;
  marginTop?: number;
};

export const XSidebarWrapper = ({
  children,
  title,
  show = false,
  width = 25,
  close,
  height = 100,
  marginTop = 0,
}: IProps) => {
  return (
    <div
      className={`details-pane w-${width} shadow-lg ${
        show ? "visible" : "visually-hidden"
      } h-${height} mt-${marginTop}`}
    >
      <div className="d-flex w-100 justify-content-between">
        <h6>{title?.toLocaleUpperCase()}</h6>
        <div>
          <i
            className="clickable-row fas fa-x"
            style={{ cursor: "pointer" }}
            onClick={close}
          >
            ✖
          </i>
        </div>
      </div>
      <div className="w-100 py-3">{children}</div>
    </div>
  );
};
