import {Field, FieldArray} from 'formik'
import {CountryCustomSelect} from './CountryCustomSelect'
import {countryCodes} from '../../../../utils/countryCodes'

export const CreateContactInformation = ({touched, errors, setCountryCode, values}: any) => {

  return (
    <FieldArray
      name="contactPersons"
      render={arrayHelpers => (
        <>
          {(values.contactPersons || []).map((contact: any, index: any) => (
            <div key={index} className="fv-row row mb-10 p-0">
              <div className="col-lg-6">
                <label className="d-flex align-items-center fs-4 f-s fw-bold mb-2">
                  <span className="required">Name of Contact Person</span>
                </label>
                <Field
                  type="text"
                  className="form-control form-control-lg py-lg-5"
                  name={`contactPersons.${index}.name`}
                  placeholder=""
                />
                {touched.contactPersons?.[index]?.name && (
                  <div className="text-danger mt-2">{errors.contactPersons?.[index]?.name}</div>
                )}
              </div>
              <div className="col-lg-6 pe-0">
                <label className="d-flex align-items-center fs-4 f-s fw-bold mb-2">
                  <span>Email Address</span>
                </label>
                <Field
                  type="text"
                  className="form-control form-control-lg py-lg-5"
                  name={`contactPersons.${index}.email`}
                  placeholder=""
                />
                {touched.contactPersons?.[index]?.email && (
                  <div className="text-danger mt-2">{errors.contactPersons?.[index]?.email}</div>
                )}
              </div>
              <div className="col-lg-6">
                <label className="d-flex align-items-center fs-4 f-s fw-bold mb-2">
                  <span>Phone Number</span>
                </label>
                <div className="input-group mb-3 col-lg-6 ">
                  <CountryCustomSelect
                    name={`dialCode`}
                    options={countryCodes}
                    style={'py-lg-6'}
                    setCountryCode={setCountryCode}
                  />
                  <Field
                    type="number"
                    className="form-control form-control-lg py-lg-5"
                    name={`contactPersons.${index}.phoneNumber`}
                    placeholder=""
                  />
                </div>
                {touched.contactPersons?.[index]?.phoneNumber && (
                  <div className="text-danger mt-2">{errors.contactPersons?.[index]?.phoneNumber}</div>
                )}
              </div>
              <div className="col-lg-6 align-content-center pt-6">
                {values.contactPersons.length > 1 && (<button
                  type="button"
                  onClick={() => arrayHelpers.remove(index)}
                  className="d-flex justify-content-between align-content-center gap-2 button-transparent text-bg-transparent border-0"
                >
                  <i className="bi bi-dash-circle-fill fs-2 fw-bolder text-danger" aria-hidden="true"></i>
                  <span>Remove Contact Person</span>
                </button>)}
              </div>
              <div className="col-lg-6 align-content-center pt-6">
                <button
                  type="button"
                  onClick={() => arrayHelpers.push({name: '', email: '', phoneNumber: ''})}
                  className="d-flex justify-content-between align-content-center gap-2 button-transparent text-bg-transparent border-0"
                >
                  <i className="bi bi-plus-circle-fill fs-2 fw-bolder text-danger" aria-hidden="true"></i>
                  <span>Add Another Contact Person</span>
                </button>
              </div>
            </div>
          ))}
        </>
      )}
    />
  )
}
