import React, {FC} from 'react'
import {useNavigate} from 'react-router-dom'
import {getInitials} from '../../../../utils/stringHelpers'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../data/redux/Store'
import {createFinancierSelectors} from '../FinanciersApiSlice'
import {useGetDocumentsQuery} from '../../documents/DocumentsApiSlice'
import {Col, Row} from 'react-bootstrap'
import CardBadge from '../../shared/components/CardBadge'
import CardDetail from '../../shared/components/CardDetail'
import {IListPageFilters} from '../../shared/interfaces/IListPageFilters'
import {ratings} from '../constants/Ratings'
import CustomSkeleton from '../../shared/components/CustomSkeleton'
import IdenticonAvatar from '../../shared/components/IdenticonAvatar'

type FinancierCardProps = {
  id: string
  page: number
  pageSize: number
  searchTerm: string
  filters: IListPageFilters
}

export const FinancierCard: FC<FinancierCardProps> = ({
                                                        id,
                                                        page,
                                                        pageSize,
                                                        searchTerm,
                                                        filters,
                                                      }) => {
  const navigate = useNavigate()
  const {selectById: selectFinancierById} = createFinancierSelectors({
    page,
    pageSize,
    searchTerm,
    ...filters,
  })
  const financier = useSelector((state: RootState) => selectFinancierById(state, id))
  const logoId = financier?.logo || null
  const {data: documentsState, isLoading: gettingLogo} = useGetDocumentsQuery(
    logoId ? [logoId] : [],
    {
      skip: !financier,
    },
  )
  const FinancierTypeMap: Record<string, string> = {
    grant: 'Grant Provider',
    finance: 'Financier',
  }
  const logo = financier ? documentsState?.entities[financier.logo] : null

  const financierInitials = getInitials(financier?.name, 3)
  const sizes = [
    Number(financier?.debtMaximumTicketSize),
    Number(financier?.grantMaximumTicketSize),
    Number(financier?.equityMaximumTicketSize),
  ].filter((size): size is number => !isNaN(size) && size > 0)
  sizes.sort((a, b) => a - b) // Sort sizes in ascending order

  const maximumTicketSize = sizes[sizes.length - 1] || 0
  const minimumTicketSize = sizes[0] || 0

  const handleNavigate = () => {
    navigate(`/financiers/${financier?.id}`, {
      state: {currentPage: page, pageSize},
    })
  }
  const getRatingLabel = (value: string | undefined): string => {
    const rating = ratings.find((r) => r.value === value)
    return rating ? rating.label : ''
  }

  const financingTypes = [
    (financier?.debtMaximumTicketSize ?? 0) > 0 ? 'Debt' : '',
    (financier?.grantMaximumTicketSize ?? 0) > 0 ? 'Grant' : '',
    (financier?.equityMaximumTicketSize ?? 0) > 0 ? 'Equity' : '',
  ].filter((type) => type)

  const financierTypes = financier?.financierType || []

  const financierTypeNames: string = financier?.financierType
    ? financier.financierType.map((type) => FinancierTypeMap[type] || type).join(', ')
    : ''


  return (
    <div className="col-md-6 col-lg-4 col-sm-12">
      <div
        onClick={handleNavigate}
        className="card border border-2 border-gray-300 mb-5 d-flex flex-column justify-content-evenly min-height-160px"
      >
        <div className="d-flex border-0 pt-3 px-5 justify-content-between align-items-center">
          {gettingLogo ? (
            <CustomSkeleton height={50} width={75} className="rounded" />
          ) : logo ? (
            <img
              src={`data:${logo.fileType};base64,${logo.fileContent}`}
              alt={`${financier?.name} logo`}
              height={50}
              width={75}
              style={{objectFit: 'contain'}}
            />
          ) : (
            <IdenticonAvatar identifier={financierInitials} size={50} />
          )}
          <div className="d-flex py-4">
            <CardBadge
              text={financierTypeNames}
            />
          </div>
        </div>
        <div className="px-5 py-2">
          <div className="d-flex justify-content-between align-items-center">
            <span
              className="fw-bolder text-dark fs-4 text-truncate"
              style={{
                maxWidth: '200%',
                display: 'block',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {financier?.name}
            </span>
          </div>
        </div>

        <Row className="px-5 pb-2">
          <Col className="pe-2">
            <CardDetail
              IsFinancierCard={true}
              title="Ticket Size"
              value={`${financier?.ticketSize}`}
            />
          </Col>
        </Row>

        <div className="px-5 d-flex flex-column align-items-left h-50px">
          <span className="fs-9 fw-bold text-gray-500 w-50">Location</span>
          <span className="text-gray-800 fs-7">
            <span className="fs-5 text-gray-800 fw-bold w-50">
              {`${financier?.region ? `${financier.region}, ` : ''}${Array.isArray(financier?.country) ? financier?.country.join(' ,') : ''}`}
            </span>
          </span>
        </div>
        <Row className="mb-2 h-30px ps-7">
          {financingTypes.map((type: string, index: number) => (
            <Col key={index} className="mb-2 flex-grow-0 px-1">
              <CardBadge key={index} text={type} />
            </Col>
          ))}
        </Row>
      </div>
    </div>
  )
}
