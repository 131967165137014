import CardBadge from '../../shared/components/CardBadge'
import {ICallDetails} from '../interfaces/ICallDetails'
import {Container, Row, Col} from 'react-bootstrap'
import {FormattedMessage, useIntl} from 'react-intl'

interface DetailSectionProps {
  title: string
  content: string | string[] | string[][]
  isBadge?: boolean
}

interface LocationScope {
  name: string
  regions: string[]
}

interface CallDescriptionProps {
  description: string
  qualifications: string[]
  postedOn: string
  fundAmount: string
  sectors: string[]
  locationScope: LocationScope[]
  deadline: string
}

const DetailSection: React.FC<DetailSectionProps> = ({title, content, isBadge = false}) => (
  <div
    className="mb-3 p-3 border-bottom border-1 border-gray-200 w-100 d-flex flex-column align-items-start justify-content-center">
    <strong>{title}</strong>
    {Array.isArray(content) ? (
      <div className="d-flex flex-wrap gap-2">
        {content.map((item: any, index) =>
          isBadge ? (
            <CardBadge key={index} text={item.toUpperCase()} />
          ) : (
            <span key={index} className="me-2">
              {item}
            </span>
          ),
        )}
      </div>
    ) : isBadge ? (
      <CardBadge text={content} isAmount={true} />
    ) : (
      <span>{content}</span>
    )}
  </div>
)

const CallDescription = ({
                           description,
                           qualifications,
                           postedOn,
                           fundAmount,
                           sectors,
                           locationScope,
                           deadline,
                         }: CallDescriptionProps) => {
  const formattedLocationScope = locationScope.length
    ? locationScope.map(({
                           name,
                           regions,
                         }) => `${name}: ${regions.length > 0 ? regions.join(', ') : 'All regions'}`).join(' | ')
    : '-'
  const intl = useIntl()
  return (
    <Container fluid>
      <Row>
        <Col sm={12} md={8} className="border-end border-1 border-gray-200 pb-6">
          <h3 className="pt-3"><FormattedMessage id={'CALLDESCRIPTION.TITLE.DESCRIPTION'}
                                                 defaultMessage={'Description'} /></h3>
          <p>{description}</p>

          <h3><FormattedMessage id={'CALLDESCRIPTION.TITLE.QUALIFICATIONS'}
                                defaultMessage={'Qualifications and Requirements'} /></h3>
          <ul className="ps-5">
            {qualifications.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </Col>
        <Col sm={12} md={4} className="px-0">
          <DetailSection
            title={intl.formatMessage({
              id: 'CALLDESCRIPTION.DETAILSECTION.TITLE.POSTED_ON',
              defaultMessage: 'Posted On',
            })}
            content={postedOn} />
          <DetailSection title={intl.formatMessage({
            id: 'CALLDESCRIPTION.DETAILSECTION.TITLE.FUND_AMOUNT',
            defaultMessage: 'Fund Amount',
          })} content={fundAmount} isBadge={true} />
          <DetailSection title={intl.formatMessage({
            id: 'CALLDESCRIPTION.DETAILSECTION.TITLE.SECTORS',
            defaultMessage: 'Sectors',
          })} content={sectors} isBadge={true} />
          <DetailSection title={intl.formatMessage({
            id: 'CALLDESCRIPTION.DETAILSECTION.TITLE.LOCATION_SCOPE',
            defaultMessage: 'Location Scope',
          })} content={formattedLocationScope} />
          <DetailSection title={intl.formatMessage({
            id: 'CALLDESCRIPTION.DETAILSECTION.TITLE.DEADLINE',
            defaultMessage: 'Deadline',
          })} content={deadline} />
        </Col>
      </Row>
    </Container>
  )
}

export default CallDescription