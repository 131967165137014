// Content.tsx
import React from 'react'
import {ListGroup, Button, Container, Row, Col, Spinner} from 'react-bootstrap'
import StatusBar from '../StatusBar'
import CardBadge from '../../../shared/components/CardBadge'
import {useLocation, useParams} from 'react-router-dom'
import {useGetApplicationStagesQuery, useGetCallApplicationsQuery, useFetchApplicantDataQuery } from '../../FinanciersApiSlice'
import {stripHTML} from '../../../../../utils/stringHelpers'

interface DetailSectionProps {
  title: string
  content: string | string[]
  isBadge?: boolean
}

const Content: React.FC = () => {
  const {callId, applicationIndex} = useParams<{callId?: string; applicationIndex?: string}>()
  const location = useLocation()
  const {stageName} = location.state || {}

  // const {data, isLoading, isSuccess, isError} = useGetCallApplicationsQuery(callId || '')
  const {data: applicationState} = useGetApplicationStagesQuery(callId || '')
  const {data, isLoading} = useFetchApplicantDataQuery({ applicantId: applicationIndex || '' })
  const stages = Object.values(applicationState?.ids || {}).filter(Boolean)
  // let application = null

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center flex-column gap-3 align-items-center pt-5">
        <Spinner animation="border" variant="danger" />
        <p className="pt-0">Loading...</p>
      </div>
    )
  }

  // if (data && stageName && applicationIndex !== undefined) {
  //   const stageData = data[stageName]
  //   if (stageData) {
  //     application = stageData[Number(applicationIndex)]
  //   }
  // }


  const description = stripHTML(data?.result.description ?? '') as string

  return (
    <Container className="pe-0">
      <Row className="border-bottom h-50px">
        <Col className=" d-flex align-items-center" md={6}>
          <h5 className="mb-0">{data?.result.applicant_name ?? ''}</h5>
        </Col>
        <Col md={6}>
          <StatusBar stages={stages} selectedStage={stageName || ''} />
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <CompanyDescription description={description} />
          <Attachments attachments={data?.result.attachments ?? []} />
        </Col>
        <Col md={4}>
          <CompanyInfo application={data?.result} />
        </Col>
      </Row>
    </Container>
  )
}

const CompanyDescription: React.FC<{description: string}> = ({description}) => (
  <div className="my-3 p-4 ps-0 border-bottom">
    <h6>About Company</h6>
    <p>
      {description}
    </p>
  </div>
)

const Attachments = ({attachments}: {attachments: any}) => (
  <div className="my-3">
    <h5 className="text-gray-400">ATTACHMENTS</h5>
    <ListGroup>
      {attachments.map((attachment: any, index: number) => (
        <ListGroup.Item key={index} className="d-flex justify-content-between align-items-center rounded">
          <div className="d-flex align-items-center gap-6">
            <i className="bi bi-paperclip"></i>
            <span>{attachment.name}</span>
          </div>
          <Button variant="link">Download</Button>
        </ListGroup.Item>
      ))}
    </ListGroup>
  </div>
)

const DetailSection: React.FC<DetailSectionProps> = ({title, content, isBadge = false}) => (
  <div
    className="mb-3 p-3 border-bottom border-1 border-gray-200 w-100 d-flex flex-column align-items-start justify-content-center">
    <strong>{title}</strong>
    {Array.isArray(content) ? (
      <div className="d-flex flex-wrap gap-2">
        {content.map((item, index) =>
          isBadge ? (
            <CardBadge key={index} text={item.toUpperCase()} />
          ) : (
            <span key={index} className="me-2">
              {item}
            </span>
          ),
        )}
      </div>
    ) : isBadge ? (
      <CardBadge text={content} isAmount={true} />
    ) : (
      <span>{content}</span>
    )}
  </div>
)

const CompanyInfo = ({application}: {application: any}) => (
  <div className="border p-3">
    <DetailSection title="Year of Incorporation" content={application?.year_of_incorporation} />
    <DetailSection title="Sector(s)" content={application?.sector_ids} isBadge={true} />
    <DetailSection title="Number of Female Employees" content={application?.number_of_female_employees} />
    <DetailSection title="Email" content={application?.applicant_email} />
    <DetailSection title="Phone Number" content={application?.applicant_email} />
  </div>
)

export default Content
