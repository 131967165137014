import * as Yup from 'yup'

export const createFinancierSchema = Yup.object({
  generalInformation: Yup.object().shape({
    name: Yup.string().required('Financier Name is required').label('Name'),
    about: Yup.string().nullable().label('About'),
    country: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.string().required(),
          name: Yup.string().required(),
        }),
      )
      .nullable(),
    region: Yup.string().nullable().label('Region'),
    logo: Yup.string().nullable().label('Logo'),
  }),
  financials: Yup.object().shape({
    financingTypes: Yup.array().of(Yup.string()).nullable(),
    maximumEquity: Yup.string().nullable(),
    maximumDebt: Yup.string().nullable(),
    maximumGrant: Yup.string().nullable(),
    currency: Yup.string().nullable(),
    financierType: Yup.array().of(Yup.string()).nullable(),
    ticketSize: Yup.string().nullable(),
  }),
  contactPersons: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required('Contact Person Name is required').label('Name'),
      email: Yup.string().nullable().label('Email'),
      phoneNumber: Yup.string()
        .nullable()
        .matches(/^[0-9]{9}$/, 'Phone number must be 9 digits')
        .label('Phone Number'),
    }),
  ),
})
