import {Field, FormikValues} from 'formik'
import {useGetFinancingTypesQuery} from '../../companies/CompaniesApiSlice'
import {currencies} from '../../shared/constants'
import NumberFormatter from '../../shared/components/NumberFormatter'
import React from 'react'

export const CreateFinancierFinancials = ({
                                            values,
                                            errors,
                                            touched,
                                            handleChange,
                                            setFieldValue,
                                            setFieldTouched,
                                          }: FormikValues) => {
  const {data: financingTypesState} = useGetFinancingTypesQuery()

  const financingTypeSelection = Object.values(financingTypesState?.entities || {})

  const financierType = [
    {id: 'finance', name: 'Financier'},
    {id: 'grant', name: 'Grant Provider'},
  ]

  return (
    <div data-kt-stepper-element="content">
      <div className="w-100">
        <div className="fv-row row mb-4 p-0">
          <div className="col-lg-6">
            <label className="d-flex align-items-center fs-4 f-s fw-bold mb-6">
              <span>Select Financing Types</span>
            </label>
            {financingTypeSelection.map((option) => (
              <label className="d-flex flex-stack cursor-pointer mb-5" key={option?.id}>
                <span className="d-flex align-items-center me-2">
                  <span className="form-check form-check-custom me-3">
                    <Field
                      className="form-check-input h-30px w-30px custom-checkbox"
                      type="checkbox"
                      name="financials.financingTypes"
                      value={option?.name}
                      checked={
                        (option && values.financials?.financingTypes?.includes(option?.name)) ||
                        false
                      }
                      onChange={(event: any) => {
                        handleChange(event)
                        setFieldValue(`maximum ${option?.name}`, '')
                        setFieldTouched(`maximum ${option?.name}`, false)
                      }}
                    />
                  </span>
                  <span className="d-flex flex-column">
                    <span className="fw-bolder fs-6">{option?.name}</span>
                  </span>
                </span>
              </label>
            ))}
          </div>
        </div>
        <div className="fv-row row mb-4 p-0">
          <div className="col-lg-6">
            <label className="d-flex align-items-center fs-4 fw-bold mb-2 f-s">
              <span>{`Ticket Size`}</span>
            </label>
            <Field
              type="text"
              className="form-control form-control-lg py-lg-5"
              name="financials.ticketSize"
              placeholder=""
            />
            {touched?.financials?.ticketSize && (
              <div className="text-danger mt-2">{errors?.financials?.ticketSize}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
