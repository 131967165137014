import React from 'react'
import {Formik, Form, Field} from 'formik'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../../_theme/helpers'
import * as Yup from 'yup'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../../data/redux/Store'
import {
  setCallFilters,
  setCompanyFilters,
  setFinancierFilters,
  setProviderFilters,
} from '../../../../data/redux/settingsReducer'
import {INameIdPair} from '../../shared/interfaces'
import NumberFormatter from '../../shared/components/NumberFormatter'
import {MultiSelect} from '../../shared/components/MultiSelect'
import {countries, ICountryValue} from '../../../../utils/countries'
import {IListPageFilters} from '../../companies/interfaces'
import {currencyNumberPairs} from '../constants'
import {fi} from 'date-fns/locale'
import {FormattedMessage} from 'react-intl'

interface Props {
  showModal: boolean
  handleClose: () => void
  companySectorsList: INameIdPair[]
  companyFinancingTypesList?: INameIdPair[]
  includeInvestmentAsk?: boolean
  includeFinancingTypes?: boolean
  includeStartAndEndDate?: boolean
  includeYearsOfOperation?: boolean
  includeSectors?: boolean
  entities?: string
  isPipelineReady?: boolean
  handleResetSelectedPage: () => void
}


export const ListPageFilters: React.FC<Props> = ({
                                                   showModal,
                                                   handleClose,
                                                   companySectorsList,
                                                   companyFinancingTypesList,
                                                   includeInvestmentAsk = true,
                                                   includeFinancingTypes = true,
                                                   includeStartAndEndDate = false,
                                                   includeYearsOfOperation = true,
                                                   includeSectors = true,
                                                   isPipelineReady = false,
                                                   entities,
                                                   handleResetSelectedPage,
                                                 }) => {
  const dispatch = useDispatch()
  const companyFilters = useSelector((state: RootState) => state.settings.companyFilters)
  const providerFilters = useSelector((state: RootState) => state.settings.providerFilters)
  const financierFilters = useSelector((state: RootState) => state.settings.financierFilters)
  const callFilters = useSelector((state: RootState) => state.settings.callFilters)
  let filters: IListPageFilters = {}
  if (entities === 'Companies') {
    filters = companyFilters
  } else if (entities === 'Service Providers') {
    filters = providerFilters
  } else if (entities === 'Financiers') {
    filters = financierFilters
  } else if (entities === 'Calls') {
    filters = callFilters
  }

  const mapIdsToObjects = (ids: string[], list: INameIdPair[]): INameIdPair[] => {
    return ids.map((id) => list.find((item) => item.id === id)).filter(Boolean) as INameIdPair[]
  }

  const mapCountryNamesToIdNamePairObjects = (
    names: string[],
    list: ICountryValue[],
  ): INameIdPair[] => {
    const selectedCountries = names
      .map((name) => list.find((item) => item.label.toLocaleLowerCase() === name))
      .filter(Boolean) as ICountryValue[]
    return selectedCountries.map((country) => ({
      id: country.value,
      name: country.label,
    }))
  }

  const FiltersValidationSchema = Yup.object().shape({
    countries: Yup.array().of(
      Yup.object().shape({
        id: Yup.string().required(),
        name: Yup.string().required(),
      }),
    ),
    startDate: Yup.string().nullable(),
    endDate: Yup.string().nullable(),
    minYearsInOperation: Yup.number().nullable(),
    maxYearsInOperation: Yup.number()
      .nullable()
      .when('minYearsInOperation', (minYearsInOperation, schema) => {
        return minYearsInOperation
          ? schema.min(Yup.ref('minYearsInOperation'), 'End year must be greater than start year')
          : schema
      }),
    sectors: Yup.array().of(
      Yup.object().shape({
        id: Yup.string().required(),
        name: Yup.string().required(),
      }),
    ),
    financingTypes: Yup.array().of(
      Yup.object().shape({
        id: Yup.string().required(),
        name: Yup.string().required(),
      }),
    ),
    minInvestmentAsk: Yup.string(),
    maxInvestmentAsk: Yup.string(),
    investmentAskCurrency: Yup.string(),
  })

  const initialValues = {
    countries: mapCountryNamesToIdNamePairObjects(filters?.countries ?? [], countries),
    minYearsInOperation: filters?.minYearsInOperation ?? '',
    maxYearsInOperation: filters?.maxYearsInOperation ?? '',
    sectors: mapIdsToObjects(filters?.sectors ?? [], companySectorsList),
    financingTypes: mapIdsToObjects(filters?.financingTypes ?? [], companyFinancingTypesList ?? []),
    minInvestmentAsk: filters?.minInvestmentAsk ?? '',
    maxInvestmentAsk: filters?.maxInvestmentAsk ?? '',
    investmentAskCurrency: filters?.investmentAskCurrency ?? 1,
    startDate: filters?.startDate ?? '',
    endDate: filters?.endDate ?? '',
  }


  const handleSubmit = (values: any) => {
    const updatedFilters: IListPageFilters = {
      minInvestmentAsk: values.minInvestmentAsk.replace(/,/g, ''),
      maxInvestmentAsk: values.maxInvestmentAsk.replace(/,/g, ''),
      investmentAskCurrency: values.investmentAskCurrency,
      countries: values.countries.map((country: INameIdPair) => country.name),
      minYearsInOperation: values.minYearsInOperation,
      maxYearsInOperation: values.maxYearsInOperation,
      startDate: values.startDate,
      endDate: values.endDate,
    }

    if (values.countries.length) {
      updatedFilters.countries = values.countries.map((country: INameIdPair) =>
        country.name.toLocaleLowerCase(),
      )
    }
    if (values.sectors.length) {
      updatedFilters.sectors = values.sectors.map((sector: INameIdPair) => sector.id)
    }

    if (values.financingTypes.length) {
      updatedFilters.financingTypes = values.financingTypes.map((type: INameIdPair) => type.id)
    }
    if (entities === 'Companies') {
      dispatch(setCompanyFilters(updatedFilters))
    } else if (entities === 'Service Providers') {
      dispatch(setProviderFilters(updatedFilters))
    } else if (entities === 'Financiers') {
      dispatch(setFinancierFilters(updatedFilters))
    } else if (entities === 'Calls') {
      dispatch(setCallFilters(updatedFilters))
    }
    handleResetSelectedPage()
    handleClose()
  }

  const handleClearButtonClick = () => {
    if (entities === 'Companies') {
      dispatch(
        setCompanyFilters({
          countries: [],
          minYearsInOperation: '',
          maxYearsInOperation: '',
          sectors: [],
          financingTypes: [],
          minInvestmentAsk: '',
          maxInvestmentAsk: '',
          investmentAskCurrency: '',
        }),
      )
    } else if (entities === 'Service Providers') {
      dispatch(
        setProviderFilters({
          countries: [],
          minYearsInOperation: '',
          maxYearsInOperation: '',
          sectors: [],
        }),
      )
    } else if (entities === 'Financiers') {
      dispatch(
        setFinancierFilters({
          countries: [],
          startDate: '',
          endDate: '',
          sectors: [],
        }),
      )
    } else if (entities === 'Calls') {
      dispatch(
        setCallFilters({
          countries: [],
          startDate: '',
          endDate: '',
          sectors: [],
        }),
      )
    }
    handleResetSelectedPage()
    handleClose()
  }

  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      id="kt_assign_project"
      aria-hidden="true"
      centered
      dialogClassName="h-auto me-0 pe-0 w-400px custom-modal"
    >
      <div role="document">
        <Formik
          initialValues={initialValues}
          validationSchema={FiltersValidationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({values, setFieldValue, errors, touched, isValid}) => {
            return (
              <Form>
                <div className="modal-header pb-2">
                  {isPipelineReady ? (<h5 className="modal-title"><FormattedMessage id={'FILTERS.LABEL.FILTER'}
                                                                                    defaultMessage={'Filter'} /> {entities}
                  </h5>) : (
                    <h5 className="modal-title">Filter {entities}</h5>)}
                  <div
                    className="btn btn-sm btn-icon btn-active-color-danger"
                    onClick={handleClose}
                  >
                    <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon-1" />
                  </div>
                </div>
                <div className="modal-body pt-2">
                  <div className="fv-row">
                    <div className="col-md-12 mb-3">
                      {isPipelineReady ? (<label
                        htmlFor="countries"
                        className="d-flex align-items-center fs-5 fw-bold mb-2 text-dark"
                      >
                        <FormattedMessage id={'FILTER.LABEL.COUNTRY'} defaultMessage={'Country'} />
                      </label>) : (<label
                        htmlFor="countries"
                        className="d-flex align-items-center fs-5 fw-bold mb-2 text-dark"
                      >Country
                      </label>)}
                      <MultiSelect
                        selectedValues={values.countries}
                        options={countries.map((country) => ({
                          id: country.value,
                          name: country.label,
                        }))}
                        setOption={(selectedOptions) => setFieldValue('countries', selectedOptions)}
                      />
                    </div>
                  </div>
                  {includeStartAndEndDate &&
                    <div className="fv-row row mb-3">
                      {isPipelineReady ? (<label className="d-flex align-items-center fs-5 fw-bold mb-1 text-dark">
                        <FormattedMessage id={'FILTER.LABEL.PERIODOFOPERATIONRANGE'}
                                          defaultMessage={'Period of Operation Range'} />
                      </label>) : (<label className="d-flex align-items-center fs-5 fw-bold mb-1 text-dark">
                        Period of Operation Range
                      </label>)}
                      <div className="col-md-6 ">
                        {isPipelineReady ? (<label
                          className="d-flex align-items-center fs-7 fw-bold mb-2 text-dark"
                        >
                          <FormattedMessage id={'FILTER.LABEL.FROM'} defaultMessage={'From'} />
                        </label>) : (<label
                          className="d-flex align-items-center fs-7 fw-bold mb-2 text-dark"
                        >
                          From
                        </label>)}
                        <Field
                          type="text"
                          className="form-control form-control-sm"
                          name="startDate"
                          placeholder="YYYY-MM-DD"
                        />
                        {touched?.startDate && (
                          <div className="text-danger mt-2">{errors?.startDate}</div>
                        )}
                      </div>
                      <div className="col-md-6">
                        {isPipelineReady ? (<label
                          className="d-flex align-items-center fs-7 fw-bold mb-2 text-dark"
                        >
                          <FormattedMessage id={'FILTER.LABEL.TO'} defaultMessage={'To'} />
                        </label>) : (<label
                          className="d-flex align-items-center fs-7 fw-bold mb-2 text-dark"
                        >
                          To
                        </label>)}

                        <Field
                          type="text"
                          className="form-control form-control-sm"
                          name="endDate"
                          placeholder="YYYY-MM-DD"
                        />
                        {touched?.endDate && (
                          <div className="text-danger mt-2">{errors?.endDate}</div>
                        )}
                      </div>
                    </div>}
                  {includeYearsOfOperation && (<div className="fv-row row mb-3">
                    {isPipelineReady ? (<label className="d-flex align-items-center fs-5 fw-bold mb-1 text-dark">
                      <FormattedMessage id={'FILTER.LABEL.YEARSOFOPERATIONRANGE'}
                                        defaultMessage={'Years of Operation Range'} />
                    </label>) : (<label className="d-flex align-items-center fs-5 fw-bold mb-1 text-dark">
                      Years of Operation Range
                    </label>)}
                    <div className="col-md-6 ">
                      {isPipelineReady ? (<label
                        className="d-flex align-items-center fs-7 fw-bold mb-2 text-dark"
                      >
                        <FormattedMessage id={'FILTER.LABEL.FROM'} defaultMessage={'From'} />
                      </label>) : (<label
                        className="d-flex align-items-center fs-7 fw-bold mb-2 text-dark"
                      >From
                      </label>)}
                      <Field
                        type="number"
                        className="form-control form-control-sm"
                        name="minYearsInOperation"
                        placeholder=""
                      />
                      {touched?.minYearsInOperation && (
                        <div className="text-danger mt-2">{errors?.minYearsInOperation}</div>
                      )}
                    </div>
                    <div className="col-md-6">
                      {isPipelineReady ? (<label
                        className="d-flex align-items-center fs-7 fw-bold mb-2 text-dark"
                      >
                        <FormattedMessage id={'FILTER.LABEL.TO'} defaultMessage={'To'} />
                      </label>) : (<label
                        className="d-flex align-items-center fs-7 fw-bold mb-2 text-dark"
                      >To
                      </label>)}
                      <Field
                        type="number"
                        className="form-control form-control-sm"
                        name="maxYearsInOperation"
                        placeholder=""
                      />
                      {touched?.maxYearsInOperation && (
                        <div className="text-danger mt-2">{errors?.maxYearsInOperation}</div>
                      )}
                    </div>
                  </div>)}
                  {includeSectors && (<div className="fv-row">
                    <div className="col-md-12 mb-3">
                      {isPipelineReady ? (<label
                        htmlFor="sectors"
                        className="d-flex align-items-center fs-5 fw-bold mb-2 text-dark"
                      >
                        <FormattedMessage id={'FILTER.LABEL.SECTOR'} defaultMessage={'Sector'} />
                      </label>) : (<label
                        htmlFor="sectors"
                        className="d-flex align-items-center fs-5 fw-bold mb-2 text-dark"
                      >Sector
                      </label>)}
                      <MultiSelect
                        selectedValues={values.sectors}
                        options={companySectorsList}
                        setOption={(selectedOptions) => setFieldValue('sectors', selectedOptions)}
                      />
                    </div>
                  </div>)}

                  {includeFinancingTypes && (
                    <div className="fv-row mb-3">
                      <div className="col-md-12">
                        {isPipelineReady ? (<label
                          htmlFor="financingTypes"
                          className="d-flex align-items-center fs-5 fw-bold mb-2 text-dark"
                        >
                          <FormattedMessage id={'FILTERS.LABEL.FINANCINGTYPE'} defaultMessage={'Financing Type'} />
                        </label>) : (<label
                          htmlFor="financingTypes"
                          className="d-flex align-items-center fs-5 fw-bold mb-2 text-dark"
                        >Financing Type
                        </label>)}
                        <MultiSelect
                          selectedValues={values.financingTypes}
                          options={companyFinancingTypesList ?? []}
                          setOption={(selectedOptions) =>
                            setFieldValue('financingTypes', selectedOptions)
                          }
                        />
                      </div>
                    </div>
                  )}
                  {includeInvestmentAsk && (
                    <>
                      <label className="d-flex align-items-center fs-5 fw-bold mb-1 text-dark">
                        Investment Ask Range
                      </label>
                      <div className="d-flex justify-content-between align-items-center gap-3">
                        <div className="d-flex flex-column align-items-start justify-content-between">
                          <label
                            className="d-flex align-items-center fs-7 fw-bold mb-2 text-dark"
                          >
                            From
                          </label>
                          <div className="d-flex align-items-center justify-content-between">
                            <Field
                              as="select"
                              name="investmentAskCurrency"
                              className="form-select form-select-sm form-select-solid rounded-end-0 currency-picker filters-page"
                              aria-label="Select financing currency for Equity"
                              multiple={false}
                              style={{flexBasis: '75%'}}
                            >
                              {Object.entries(currencyNumberPairs).map(([key, value]) => (
                                <option value={value}>{key}</option>
                              ))}
                            </Field>
                            <NumberFormatter
                              name="minInvestmentAsk"
                              className="form-control-sm border-start-0 rounded-start-0 currency-picker"
                              setFieldValue={setFieldValue}
                              initialValue={values.minInvestmentAsk}
                            />
                          </div>
                        </div>
                        <div className="d-flex flex-column align-items-start">
                          <label
                            className="d-flex align-items-center fs-7 fw-bold mb-2 text-dark"
                          >
                            To
                          </label>
                          <div className="d-flex align-items-center justify-content-between">
                            <Field
                              as="select"
                              name="investmentAskCurrency"
                              className="form-select form-select-sm form-select-solid rounded-end-0 currency-picker filters-page"
                              aria-label="Select financing currency for Equity"
                              multiple={false}
                              style={{flexBasis: '75%'}}
                            >
                              {Object.entries(currencyNumberPairs).map(([key, value]) => (
                                <option value={value}>{key}</option>
                              ))}
                            </Field>
                            <NumberFormatter
                              name="maxInvestmentAsk"
                              className="form-control-sm border-start-0 rounded-start-0 currency-picker"
                              setFieldValue={setFieldValue}
                              initialValue={values.maxInvestmentAsk}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                {isPipelineReady ? (
                    <div className="modal-footer mt-0 d-flex justify-content-between">
                      <button type="submit" className="btn btn-danger" disabled={!isValid}>
                        <FormattedMessage id={'BUTTONS.APPLY'} defaultMessage={'Apply'} />
                      </button>
                      <button type="reset" className="btn btn-light" onClick={handleClearButtonClick}>
                        <FormattedMessage id={'BUTTONS.CLEAR'} defaultMessage={'Clear'} />
                      </button>
                    </div>
                  ) :
                  (
                    <div className="modal-footer mt-0 d-flex justify-content-between">
                      <button type="submit" className="btn btn-danger" disabled={!isValid}>
                        Apply
                      </button>
                      <button type="reset" className="btn btn-light" onClick={handleClearButtonClick}>
                        Clear
                      </button>
                    </div>
                  )}
              </Form>
            )
          }}
        </Formik>
      </div>
    </Modal>
  )
}
