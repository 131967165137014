import React, {useEffect, useState} from 'react'
import {Container, Row, Col, Tab, Nav} from 'react-bootstrap'
import Header from '../components/HeaderPage'
import Content from '../components/candidates-components/ContentComponent'
import {useLocation} from 'react-router-dom'
import EmailsComponent from '../components/candidates-components/CandidatesEmailComponent'

const CandidatesDetailPage: React.FC = () => {
  const location = useLocation()
  const [activeTab, setActiveTab] = useState('details')
  useEffect(() => {
    if (location.state?.fromHeader) {
      setActiveTab('details')
    }
  }, [location.state])
  const tabs = [
    {
      id: 'details',
      label: 'Applicant Details',
      icon: 'fas fa-pen',
      activeColor: 'text-danger',
    },
    {
      id: 'emails',
      label: 'Applicant Correspondence',
      icon: 'fas fa-inbox',
      activeColor: 'text-danger',
    },
  ]
  return (
    <Container fluid>
      <Row>
        <Col>
          <Header />
        </Col>
      </Row>
      <Row className="mt-3 bg-white">
        {/* <Col>
          <Tab.Container defaultActiveKey="details">
            <Nav variant="tabs" className="mb-3">
              <Nav.Item>
                <Nav.Link eventKey="details">Applicant Details</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="emails">Applicant Emails</Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="details">
                <Content />
              </Tab.Pane>
              <Tab.Pane eventKey="emails">
                <EmailsComponent />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Col> */}
        <div className="d-flex flex-column align-items-start">
          <div className="card w-100 mb-6">
            <div className="d-flex border-bottom border-gray-200">
              {tabs.map((tab) => (
                <button
                  key={tab.id}
                  className={`d-flex align-items-center px-4 py-4 ms-8 me-3 btn btn-link fs-6 ${activeTab === tab.id
                    ? `${tab.activeColor} border-bottom border-2 border-danger`
                    : 'text-muted'
                  }`}
                  onClick={() => setActiveTab(tab.id)}
                >
                  <i className={`${tab.icon} ${activeTab === tab.id ? tab.activeColor : ''}`}></i>
                  <span className={activeTab === tab.id ? tab.activeColor : ''}>{tab.label}</span>
                </button>
              ))}
            </div>
            {activeTab === 'details' && (
              <Content />
            )}
            {activeTab === 'emails' && <EmailsComponent />}
          </div>
        </div>
      </Row>
    </Container>
  )
}

export default CandidatesDetailPage