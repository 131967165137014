import React, {useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import EntityListSearch from '../../shared/components/EntityListSearch'
import FilterButton from '../../shared/components/FilterButton'
import CardBadge from '../../shared/components/CardBadge'
import {useGetApplicationStagesQuery, useGetCallApplicationsQuery} from '../FinanciersApiSlice'
import {timeAgo} from '../../../../utils/dateHelpers'
import {Spinner} from 'react-bootstrap'
import {FormattedMessage} from 'react-intl'

const Card = ({data}: any) => (
  <div className="card border border-gray-300 mt-3">
    <div className="p-3">
      <h6 className="fw-bold fs-7">{data.title}</h6>
      <CardBadge text={data.badgeText.toUpperCase()} />
      {data.email && (
        <div className="d-flex align-items-center gap-2">
          <i className={`fas fa-envelope`}></i> <span className="fs-7">{data.email}</span>
        </div>
      )}
      {data.phone && (
        <div className={`d-flex align-items-center gap-2 border-bottom border-gray-300 pb-2`}>
          <i className={`fas fa-phone phone-icon-transform`}></i>{' '}
          <span className="fs-7">{data.phone}</span>
        </div>
      )}
      <div className="d-flex justify-content-between align-items-center gap-2 pt-1">
        <span className="fs-8 fw-light light-gray-text"><FormattedMessage id={'CALLCANDIDATES.CARD.TITLE.EMPLOYEES'}
                                                                          defaultMessage={'No of Employees'} />: {data.employees} </span>
        <span className="fs-8 fw-light light-gray-text">{timeAgo(data.date, true)}</span>
      </div>
    </div>
  </div>
)

const CallCandidates = () => {
  const [searchTerm, setSearchTerm] = useState('')
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [showFiltersModal, setShowFiltersModal] = useState<boolean>(false)
  const {callId, financierId} = useParams<{callId: string; financierId: string}>()
  const [kanbanTabs, setKanbanTabs] = useState<any[]>([])
  const {
    data: applicationsState,
    isLoading: isFetchingApplications,
    error: isFetchingApplicationsError,
    isSuccess,
    isError,
  } = useGetCallApplicationsQuery(callId || '')
  const {data: applicationStagesState, isLoading: isFetchingApplicationStages} =
    useGetApplicationStagesQuery(callId || '')
  const stages = Object.values(applicationStagesState?.ids || {}).filter(Boolean)

  const backgroundColors = ['#FFF0D8', '#EDDFFE', '#B6EDD7', '#F9DDDD', '#D9E5F8']
  const countBackgroundColors = ['#FBAB10', '#5F1ED9', '#15A87A', '#D83232', '#3B77DA']

  const getBackgroundColor = (index: number) => {
    return backgroundColors[index % backgroundColors.length]
  }

  const getCountBackgroundColor = (index: number) => {
    return countBackgroundColors[index % countBackgroundColors.length]
  }

  useEffect(() => {
    if (applicationsState && applicationStagesState) {
      const transformedData = stages.map((stage, index) => ({
        label: stage,
        backgroundColor: getBackgroundColor(index),
        count: applicationsState[stage]?.length || 0,
        countBackgroundColor: getCountBackgroundColor(index),
        cards: (applicationsState[stage] || []).map((application: any) => ({
          title: application.applicantName,
          badgeText: application.jobCategory,
          email: application?.applicantEmail || '',
          phone: application.phone,
          employees: application.numberOfEmployees,
          date: application.applicationDate,
          id: application.id,
        })),
      }))
      setKanbanTabs(transformedData)
    }
  }, [applicationsState, applicationStagesState])

  const toUrlFriendly = (label: string) => {
    return label.replace(/\s+/g, '-')
  }

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }
  const handleFiltersModal = () => {
    setShowFiltersModal(!showFiltersModal)
  }
  let content
  if (isFetchingApplications && isFetchingApplicationStages) {
    content = (
      <div className="d-flex justify-content-center flex-column gap-3 align-items-center">
        <Spinner animation="border" variant="danger" />
        <p className="pt-0"><FormattedMessage id={'CALLCANDIDATES.LOADING.MESSAGE'} defaultMessage={'Loading...'} /></p>
      </div>
    )

  } else if (isSuccess) {
    content = stages.length > 0 && kanbanTabs.length > 0 ? (
      <div className="kanban-container">
        {kanbanTabs.map((tab, index) => (
          <div key={index} className="kanban-column">
            <div
              className={`d-flex align-items-center justify-content-between tab-header min-height-60px`}
              style={{backgroundColor: tab.backgroundColor}}
            >
              <span className="fw-bold">{tab.label}</span>
              <div className={`tab-count`} style={{backgroundColor: tab.countBackgroundColor}}>
                <span className="text-white">{tab.count}</span>
              </div>
            </div>
            {tab.cards.map((card: any, cardIndex: number) => (
              <Link
                to={`/financier/${financierId}/calls/${callId}/applications/${toUrlFriendly(
                  tab.label,
                )}/${card.id}`}
                className="text-decoration-none text-dark"
                key={cardIndex}
                state={{stageName: tab.label}}
              >
                <Card key={cardIndex} data={card} />
              </Link>
            ))}
          </div>
        ))}
      </div>
    ) : (
      <div className="d-flex justify-content-start flex-column gap-3 align-items-start">
        <em>No stages added yet.</em>
      </div>
    )
  }
  return (
    <div className="px-5 mt-5 pb-5">
      <div className="d-flex align-items-center justify-content-between mb-3">
        <span className="fs-6 fw-bold"><FormattedMessage id={'MENU.CALLS.CANDIDATES'}
                                                         defaultMessage={'Candidates'} /></span>
      </div>
      {content}
    </div>
  )
}

export default CallCandidates
