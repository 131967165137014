import React from 'react'
import {Link} from 'react-router-dom'
import {useParams} from 'react-router'
import CardBadge from '../../shared/components/CardBadge'
import {IFinancierCall} from '../interfaces'
import {printDateWithOrdinal, timeAgo} from '../../../../utils/dateHelpers'
import {stripHTML} from '../../../../utils/stringHelpers'
import {Col, Row} from 'react-bootstrap'
import CardDetail from '../../shared/components/CardDetail'
import {FormattedMessage, useIntl} from 'react-intl'

interface CallCardProps {
  call: IFinancierCall
}

const CallsCard: React.FC<CallCardProps> = ({call}) => {
  const {financierId} = useParams<{financierId: string}>()
  const maxLength = 200
  const plaintext = stripHTML(call?.description)
  const displayText =
    plaintext.length > maxLength ? `${plaintext.slice(0, maxLength)}...` : plaintext
  const callAmount = `${call?.callAmount ? call.callAmount.toLocaleString() : 'NOT SPECIFIED'
  }`
  const intl = useIntl()
  return (
    <Link
      to={`/financier/${financierId}/calls/${call?.id}`}
      className="text-decoration-none text-dark"
    >
      <div className="card border border-gray-300 mb-4 w-440px h-300px position-relative px-6 pt-5">
        <span
          className="position-absolute top-0 end-0 bg-black text-white px-3 py-1 rounded-start-0 rounded-top fs-custom">
          <FormattedMessage id={'CARD.LABEL.UPLOADED'} defaultMessage={'Uploaded'} /> {timeAgo(call?.dateUploaded)}
        </span>
        <div className="d-flex flex-column justify-content-between gap-4">
          <h5 className="fw-bold fs-4 pt-5 mb-0 h-30px">{call?.name ?? ''}</h5>
          <p className="text-dark custom-line-spacing h-100px fs-7 my-0">{displayText}</p>
          <Row className="h-50px px-0">
            <Col className="pe-2">
              <CardDetail
                title={intl.formatMessage({id: 'CARD.LABEL.DEADLINE', defaultMessage: 'Deadline'})}
                showYearOfOperationIcon={true}
                value={call?.deadline ? printDateWithOrdinal(call?.deadline) : '-'}
                IsCall={true}
              />
            </Col>
            <Col className="ps-2">
              <CardDetail
                title={intl.formatMessage({id: 'CARD.LABEL.LOCATION', defaultMessage: 'Location Scope'})}
                showLocationIcon={true}
                value={
                  call?.country?.length
                    ? call.country
                      .map((country) => {
                        return `${country.name}`
                      })
                      .join(', ')
                    : '-'
                }
                IsCall={true}
              />
            </Col>
          </Row>

          <div className="d-flex justify-content-start align-items-center h-60px gap-2">
            <CardBadge
              text={callAmount}
              isAmount={true}
            />
            {call?.sectors.map((sector: string, index: number) => (
              <CardBadge text={sector.toUpperCase()} key={index} />
            ))}
          </div>
        </div>
      </div>
    </Link>
  )
}

export {CallsCard}
