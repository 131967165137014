import React, { useState } from 'react';
import { Button, Col, ListGroup, Modal, Pagination, Row, Spinner, Table } from 'react-bootstrap';
import { useLocation, useParams } from 'react-router-dom';
import { useFetchEmailDataQuery, useGetApplicationStagesQuery, useGetCallApplicationsQuery } from '../../FinanciersApiSlice';
import { IApplicantsEmail } from '../../interfaces/IApplicantEmail';
import { printDateWithOrdinal } from '../../../../../utils/dateHelpers';
import { XSidebarWrapper } from '../../../shared/components/Drawer';

interface Email {
    id: number;
    subject: string;
    email_from: string;
    email_to: string;
    body: string; // HTML content of the email body
}

const EmailsComponent: React.FC = () => {
    const { callId, applicationIndex } = useParams()


    const [selectedEmail, setSelectedEmail] = useState<IApplicantsEmail | null>(null);
    const [showModal, setShowModal] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(5)

    const { data: emails, isFetching, isError } = useFetchEmailDataQuery({
        applicantId: applicationIndex || '',
        page,
        pageSize
    })

    const handleEmailClick = (email: IApplicantsEmail) => {
        setSelectedEmail(email);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setSelectedEmail(null);
        setShowModal(false);
    };

    const handlePageChange = (newPage: number) => {
        setPage(newPage);
    };

    if (isFetching) {
        return (
            <div className="d-flex justify-content-center flex-column gap-3 align-items-center">
                <Spinner animation="border" variant="danger" />
                <p className="pt-0">Loading...</p>
            </div>
        )

    }

    if (isError || !emails) {
        return (
            <div className="text-center">
                <p>Error loading emails.</p>
            </div>
        );
    }

    const { data, meta } = emails;
    // Calculate the range of entries
    const startEntry = (meta.page - 1) * meta.pageSize + 1;
    const endEntry = Math.min(meta.page * meta.pageSize, meta.total);

    return (
        <div className="p-4">
            <h4>Emails</h4>
            <div className="table-responsive">
                <Table bordered hover>
                    <thead className="custom-table">
                        <tr>
                            <th>Subject</th>
                            <th>From</th>
                            <th>Type</th>
                            <th>Created On</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((email) => (
                            <tr key={email.id} onClick={() => handleEmailClick(email)} style={{ cursor: 'pointer' }}>
                                <td>{email.subject || ''}</td>
                                <td>{email.emailFrom}</td>
                                <td>{email.messageType}</td>
                                <td>{printDateWithOrdinal(email.createdAt)}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
            <div className="d-flex justify-content-between align-items-center mt-3">
                <div>
                    <p className="text-muted">
                        Showing {startEntry} to {endEntry} of {meta.total} entries
                    </p>
                </div>
                <Pagination className='custom-pagination'>
                    <Pagination.Prev onClick={() => handlePageChange(meta.page - 1)} disabled={meta.page === 1} />
                    {[...Array(meta.totalPages)].map((_, idx) => (
                        <Pagination.Item
                            key={idx + 1}
                            active={meta.page === idx + 1}
                            onClick={() => handlePageChange(idx + 1)}
                        >
                            {idx + 1}
                        </Pagination.Item>
                    ))}
                    <Pagination.Next onClick={() => handlePageChange(meta.page + 1)} disabled={meta.page === meta.totalPages} />
                </Pagination>
            </div>
            <XSidebarWrapper
                close={handleCloseModal}
                show={showModal}
                title={selectedEmail?.messageType}
                width={35}
                height={75}
                marginTop={5}
            >
                <div className='px-4 py-6'>
                    {selectedEmail && (
                        <div>
                            <h5 className="mb-3 text-modal-secondary">{selectedEmail.subject}</h5>
                            <div className="email-details">
                                <p>
                                    <strong>From:</strong> <span className="text-muted">{selectedEmail.emailFrom}</span>
                                </p>
                                <p>
                                    <strong>To:</strong> <span className="text-muted">{selectedEmail.emailTo}</span>
                                </p>
                                <hr />
                                <div>
                                    <h6 className="text-dark">Body:</h6>
                                    <div
                                        className="email-body"
                                        dangerouslySetInnerHTML={{ __html: selectedEmail.body }}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </XSidebarWrapper>
        </div>
    );
};

export default EmailsComponent;
