import {FC} from 'react'
import {useLang} from './Blacki18n'
import {IntlProvider} from 'react-intl'

import enMessages from './messages/en.json'
import frMessages from './messages/fr.json'
import arMessages from './messages/ar.json'

const allMessages = {
  en: enMessages,
  fr: frMessages,
  ar: arMessages,
}

const I18nProvider: FC = ({children}) => {
  const locale = useLang()
  const messages = allMessages[locale]

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  )
}

export {I18nProvider}
